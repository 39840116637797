
//define([ 'jquery', 'underscore', "backbone","rsvp" ], function($, _, Backbone, RSVP) {

  // The client ID is obtained from the Google Developers Console
  // at https://cloud.google.com/console.
  // If you run this code from a server other than http://localhost,
  // you need to register your own client ID.
  var OAUTH2_CLIENT_ID = '143968598373-ipscd64hqhqk1q0cvbhge56rcf85s513.apps.googleusercontent.com';
  var TREBBLE_WEB_AUTH2_CLIENT_ID  = "143968598373-g03jv17lckno3u9n2qr2isu44i4atg88.apps.googleusercontent.com";
  var API_KEY= 'AIzaSyBveA-29YCS6MpRPrE1Bf2ksLZ4M-ViUPY';
  var OAUTH2_SCOPES = [
  'https://www.googleapis.com/auth/youtube'
  ];


  /****** START CODE TO LOAD YOUTUBE IFRAME *******/
  _onYoutubeIframeAPIReadyPromise = {};
  _onYoutubeIframeAPIReadyPromise._pendingCallbacks =[];
  _onYoutubeIframeAPIReadyPromise._resolve = function(){
    this._wasResolved =  true;
    for(let i in this._pendingCallbacks)
    {
      var cb = this._pendingCallbacks[i];
      cb();
    }
  };

  _onYoutubeIframeAPIReadyPromise.then = function(pendingCallback){
   if(this._wasResolved){
    pendingCallback();
  }else{
    this._pendingCallbacks.push(pendingCallback);
  }
};


function onYouTubeIframeAPIReady(){
  _onYoutubeIframeAPIReadyPromise._resolve();
}

/****** END CODE TO LOAD YOUTUBE IFRAME *******/

function loadGAPIClientInterfaces() {
  gapi.client.setApiKey(API_KEY);
  gapi.client.load('youtube', 'v3', function() {
   //console.log("Youtube gapi loaded!!!");
   _onYoutubeLoadedPromise._resolve();
   //handleAPILoaded();
 });


  auth2 = gapi.auth2.init({
    client_id: TREBBLE_WEB_AUTH2_CLIENT_ID,
    cookiepolicy: 'single_host_origin',
    // Request scopes in addition to 'profile' and 'email'
    //scope: 'additional_scope'
  });
  _onGAuthLoadedPromise._resolve();

}

_onYoutubeLoadedPromise = {};
_onYoutubeLoadedPromise._pendingCallbacks =[];

_onYoutubeLoadedPromise._resolve = function(){
  this._wasResolved =  true;
  for(let i in this._pendingCallbacks)
  {
    var cb = this._pendingCallbacks[i];
    cb();
  }
};

_onYoutubeLoadedPromise.then = function(pendingCallback){
  if(this._wasResolved){
    pendingCallback();
  }else{
    this._pendingCallbacks.push(pendingCallback);
  }
};


_onGAuthLoadedPromise = {};
_onGAuthLoadedPromise._pendingCallbacks =[];

_onGAuthLoadedPromise._resolve = function(){
  this._wasResolved =  true;
  for(let i in this._pendingCallbacks)
  {
    var cb = this._pendingCallbacks[i];
    cb();
  }
};

_onGAuthLoadedPromise.then = function(pendingCallback){
  if(this._wasResolved){
    pendingCallback();
  }else{
    this._pendingCallbacks.push(pendingCallback);
  }
};
//})